import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {}

const SmallBar = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.81 2" {...props}>
      <defs>
        <linearGradient
          id="a"
          y1={1}
          x2={132.81}
          y2={1}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#c2986b" stopOpacity={0.3} />
          <stop offset={0.51} stopColor="#c2986b" />
          <stop offset={1} stopColor="#c2986b" stopOpacity={0.3} />
        </linearGradient>
      </defs>
      <g data-name="Layer 2">
        <g data-name="Level 1">
          <path
            d="M0 1Q16.61.42 33.2.22C44.27.06 55.34 0 66.4 0s22.14.06 33.21.22 22.13.39 33.2.78q-16.61.59-33.2.78C88.54 1.94 77.47 2 66.4 2s-22.13-.06-33.2-.22S11.07 1.39 0 1Z"
            style={{
              fill: "url(#a)",
            }}
            data-name="Welcome"
          />
        </g>
      </g>
    </svg>
  );
};

export default SmallBar;
