import React from 'react';

import { Button, Col, Row } from 'reactstrap';
import { Flex, FlexColumn, FlexRow, Navbar } from '../components';
import BaseLayout from '../components/Layouts/BaseLayout';
import useNavigationScroller from '../hooks/useNavigationScroller';
import SideDiamond from '../components/SideDiamond/SideDiamond';
import LargeBar from '../components/Bars/LargeBar';

import PartnersFooter from '../components/PartnersFooter/PartnersFooter';
import { Link } from 'gatsby';
import inquireDataJson from '../data/inquire.json';
import homeDataJson from '../data/home.json';
import SmallBar from '../components/Bars/SmallBar';
import Markdown from '../components/Markdown/Markdown';

export const InquirePageInternal = (inquireData: typeof inquireDataJson) => {
  const wrapper = React.useRef<HTMLDivElement>(null);
  useNavigationScroller(wrapper);

  return (
    <BaseLayout title="Inquire">
      <Navbar />

      <Row className="flex-fill">
        <Col md={4}></Col>
        <Col className="d-flex flex-column p-3 flex-fill" md={8}>
          <div className="pt-2 px-2">
            <Link to="/">
              <img className="img-fluid hero-opacity" src={require('../images/logo-horizontal.svg').default} alt="Aviation Safety Solutions" style={{ height: '20vh' }} />
            </Link>
          </div>
        </Col>
      </Row>

      <div className="wrapper" ref={wrapper}>
        <Row>
          <Col lg={{ offset: 2, size: 8 }}>
            <section className="px-2 pt-5 body-bg">
              <Row className="text-center serif">
                <Col md={12}>
                  <Markdown source={inquireData.headingMarkdown} />
                </Col>
              </Row>

              <Row className="mt-5">
                <Col md={6}>
                  <Markdown source={inquireData.mainMarkdown} />
                </Col>
                <Col md={6}>
                  <FlexRow justifyAround>
                    <div style={{ width: 200 }}>
                      <img alt="Skyla logo" className="text-accent" src={require('../assets/img/skyla.svg').default} />
                    </div>
                  </FlexRow>
                  <div className="mt-4">
                    <img alt="Inquire about Skyla" className="text-accent img-fluid" src={inquireData.image} />
                  </div>

                  <FlexRow className="mt-4" justifyCenter>
                    <FlexRow>
                      <div className="mr-4">
                        <Link className="hero-opacity btn btn-secondary" to="/contact">Inquire</Link>
                      </div>
                      <div>
                        <a href="https://app.avsafetysolutions.com/" target="_blank" rel="noopener noreferrer" className="btn btn-secondary hero-opacity">Log In</a>
                      </div>
                    </FlexRow>
                  </FlexRow>
                </Col>
              </Row>

              <Row className="mt-5 serif text-center">
                <Col md={12}>
                  <Markdown source={inquireData.welcomeMarkdown} />
                </Col>
              </Row>

              <Row className="mt-3">
                <Col md={{ offset: 3, size: 6 }}>
                  <div className="mt-5 text-center">
                    <h2 className="text-accent mb-4">We help you</h2>
                    {
                      homeDataJson.weHelpYou.map((section, i) => (
                        <React.Fragment key={i}>
                          <h1 className="heading text-uppercase my-2">{section.label}</h1>
                          {i !== homeDataJson.weHelpYou.length - 1 && (
                            <div className="hero-opacity my-1">
                              <SmallBar style={{ width: '60%' }} />
                            </div>
                          )}
                        </React.Fragment>
                      ))
                    }
                  </div>
                </Col>
              </Row>
            </section>
          </Col>
        </Row>

        <div className="hero-opacity my-1 mt-5 text-center">
          <LargeBar style={{ width: '60%' }} />
        </div>

        <section className="pt-5">
          <PartnersFooter />
        </section>

        <div className="hero-opacity mt-3 pb-5 text-center text-secondary">
          <FlexRow justifyCenter childSpacingX={1}>
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
            <i className="mdi mdi-36px mdi-star" />
          </FlexRow>
        </div>
      </div>
    </BaseLayout>
  );
};

export default () => <InquirePageInternal {...inquireDataJson} />;
