import React from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {}

const LargeBar = (props: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 346.88 2" {...props}>
      <defs>
        <linearGradient
          id="a"
          y1={1}
          x2={346.88}
          y2={1}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#c2986b" stopOpacity={0.3} />
          <stop offset={0.51} stopColor="#c2986b" />
          <stop offset={1} stopColor="#c2986b" stopOpacity={0.3} />
        </linearGradient>
      </defs>
      <g data-name="Layer 2">
        <g data-name="Level 1">
          <path
            d="M0 1 43.36.51 86.72.23 173.44 0l86.72.23 43.36.28c14.48.16 28.91.26 43.36.49-14.45.23-28.91.32-43.36.49l-43.36.28-86.72.23-86.72-.24-43.36-.27Z"
            style={{
              fill: "url(#a)",
            }}
            data-name="Welcome"
          />
        </g>
      </g>
    </svg>
  );
};

export default LargeBar;
